import React from 'react'

const About = () => {
  return (
    <div>
      <h1 style={{textAlign:'center',fontSize:'5rem',backgroundColor:'orange',color:'white',marginTop:'50px',padding:"80px"}}>Coming Soon !</h1>
    </div>
  )
}

export default About